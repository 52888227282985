import React from "react";
import { HeaderContainer, PageContainer } from "styles/global.style";
import {
  CircleBackground,
  CTA,
  DescriptionCol,
  ImageCol,
  StyledAuthorHeader,
} from "./index.style";
import { Row } from "styled-bootstrap-grid";
import { Position } from "app-types/position";
import Heading from "components/core/Typography/Heading";
import Fade from "components/core/Animation/extended/Fade";
import Breadcrumbs, { BreadCrumb } from "components/core/Breadcrumbs";
import Icon, { IconType } from "components/core/Icon";
import { CTAColor } from "app-types/color";
import { Author } from "app-types/template";
import { useTranslation } from "react-i18next";
import { getAuthorWebsitePath } from "utils/path";
import { NOT_HIRABLE_AUTHORS } from "constants/community";

export interface AuthorHeaderProps {
  author: Author;
  ctaButton?: {
    show: boolean;
    text: string;
    path?: string;
    externalLink?: string;
    icon?: IconType;
    color?: CTAColor;
  };
  breadcrumbs?: BreadCrumb[];
  image: {
    src: string;
    alt: string;
    position?: Position;
    maxWidth?: number;
  };
  circleBackground?: {
    show: boolean;
    backgroundColor?: string;
  };
}

const AuthorHeader = (props: AuthorHeaderProps) => {
  const { t } = useTranslation();

  return (
    <StyledAuthorHeader>
      <HeaderContainer>
        <PageContainer>
          <Row>
            <DescriptionCol
              lg={5}
              order={2}
              lgOrder={
                props.image.position && props.image.position === "left" ? 2 : 1
              }
            >
              {props.breadcrumbs && (
                <Fade direction={"up"}>
                  <Breadcrumbs elements={props.breadcrumbs} />
                </Fade>
              )}
              <Fade direction={"up"}>
                <Heading level={1}>{props.author.public_name}</Heading>
              </Fade>
              <Fade direction={"up"} delay={500}>
                <p>{props.author.bio}</p>
              </Fade>
              {!NOT_HIRABLE_AUTHORS.includes(props.author.username) && (
                <Fade direction={"up"} delay={1000}>
                  <CTA
                    href={getAuthorWebsitePath(props.author.website)}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {t("components.ui.base.authorHeader.hire", {
                      name: props.author.public_name,
                    })}
                    <Icon type={"arrow-right"} size={18} />
                  </CTA>
                </Fade>
              )}
            </DescriptionCol>
            <ImageCol
              lg={7}
              order={1}
              lgOrder={
                props.image.position && props.image.position === "left" ? 1 : 2
              }
            >
              <Fade direction={"up"}>
                <CircleBackground
                  background={props.circleBackground?.backgroundColor}
                />
                <img alt={props.image.alt} src={props.image.src} />
              </Fade>
            </ImageCol>
          </Row>
        </PageContainer>
      </HeaderContainer>
    </StyledAuthorHeader>
  );
};

export default AuthorHeader;
