import { Author } from "app-types/template";
import GlobalWrapper from "components/core/GlobalWrapper";
import React, { useMemo } from "react";
import SEO from "components/core/SEO";
import { getAccountPicturePath, getCommunityAuthorPagePath } from "utils/path";
import { graphql } from "gatsby";
import { AuthorQueryResult } from "app-types/author";
import EmailTemplates from "components/ui/extended/EmailTemplates";
import ChatTemplates from "components/ui/extended/ChatTemplates";
import CannedResponseTemplates from "components/ui/extended/CannedResponseTemplates";
import SurveyTemplates from "components/ui/extended/SurveysTemplates";
import AuthorHeader from "components/ui/base/AuthorHeader";
import { TRIGGER_TEMPLATES } from "constants/templates";

export interface AuthorProps {
  pageContext: {
    author: Author;
  };
  data: AuthorQueryResult;
  location: Location;
}

const CommunityAuthorPage = ({ pageContext, data, location }: AuthorProps) => {
  const cannedResponseTemplates = data.allCannedResponsesTemplates.nodes;
  const emailTemplates = data.allEmailsTemplates.nodes;
  const chatTemplates = TRIGGER_TEMPLATES.filter(
    (template) => template.author.username === pageContext.author.username
  );
  const surveyTemplates = data.allSurveysTemplates.nodes;

  return (
    <GlobalWrapper withLayout location={location}>
      <AuthorHeader
        author={pageContext.author}
        image={{
          src: getAccountPicturePath(pageContext.author.account_id),
          alt: pageContext.author.public_name,
          position: "left",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Community",
            path: "/community/",
          },
          {
            name: pageContext.author.public_name,
            path: getCommunityAuthorPagePath(pageContext.author.username),
          },
        ]}
      />

      <SEO
        title={pageContext.author.public_name}
        description={pageContext.author.bio}
      />
      {emailTemplates.length > 0 ? (
        <EmailTemplates templates={emailTemplates} />
      ) : (
        <></>
      )}
      {chatTemplates?.length > 0 ? (
        <ChatTemplates templates={chatTemplates} />
      ) : (
        <></>
      )}
      {cannedResponseTemplates.length > 0 ? (
        <CannedResponseTemplates templates={cannedResponseTemplates} />
      ) : (
        <></>
      )}
      {surveyTemplates.length > 0 ? (
        <SurveyTemplates templates={surveyTemplates} />
      ) : (
        <></>
      )}
    </GlobalWrapper>
  );
};

export const authorPageQuery = graphql`
  query AuthorQuery($username: String!) {
    allCannedResponsesTemplates(
      filter: { author: { username: { eq: $username } } }
    ) {
      nodes {
        canned_response_template_id
        emoji
        description
        background
        canned_response {
          is_shared
          shortcut
          text
          title
        }
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
    allEmailsTemplates(filter: { author: { username: { eq: $username } } }) {
      nodes {
        id
        name
        subject
        thumbnail_url
        description
        created_at
        community_email_template_id
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
    allSurveysTemplates(filter: { author: { username: { eq: $username } } }) {
      nodes {
        background
        description
        emoji
        survey_template_id
        name
        questions {
          title
          subtitle
          choices {
            value
          }
        }
        survey {
          name
          thankyou_text
        }
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
  }
`;

export default CommunityAuthorPage;
